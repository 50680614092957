"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Home = createSvgIcon(
  <path d="M6 19.0004H9.6923V13.1158H14.3077V19.0004H18V10.0004L12 5.46194L6 10.0004V19.0004ZM5 20.0004V9.50039L12 4.21191L19 9.50039V20.0004H13.3077V14.1158H10.6923V20.0004H5Z" />,
  "Home",
)

const HomeIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Home sx={{ fill: _fill, ...sx }} {...rest} />
}

export default HomeIcon
