"use client"
import { useCallback, useContext } from "react"
import { PermissionContext } from "./PermissionProvider"

export const usePermission = () => {
  const { hasPermission, allowRole } = useContext(PermissionContext)
  const hasAccess = useCallback((askFor?: string[], blackList?: string[]) => {
    return [
      hasPermission(askFor, blackList),
      allowRole(askFor, blackList),
    ].some(Boolean)
  }, [])
  return { hasAccess, hasPermission, allowRole }
}
