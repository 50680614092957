"use client"
import { useQuery } from "@urql/next"
import { flatMap, flow, intersection, isEmpty, keys, pick } from "lodash"
import { createContext, useCallback, useEffect, useState } from "react"

interface RolePermission {
  [role: string]: string[]
}

export interface PermissionProviderContextProps {
  hasPermission: (askFor?: string[], blackList?: string[]) => boolean
  allowRole: (askFor?: string[], blackList?: string[]) => boolean
  permissions: string[]
  roles?: RolePermission
}
export interface PermissionProviderProps {
  children: React.ReactNode
  availablePermissions: string[]
  availableRoles: RolePermission
  availableFeatureFlags?: string[]
  query: string
}
export const PermissionContext = createContext<PermissionProviderContextProps>({
  hasPermission: () => true,
  allowRole: () => true,
  permissions: [],
})
export const PermissionProvider = (props: PermissionProviderProps) => {
  const {
    children,
    availablePermissions,
    availableRoles,
    availableFeatureFlags,
    query,
  } = props
  const [permissions, setPermission] = useState(() => availablePermissions)
  const [roles] = useState(() => availableRoles)
  const [{ data }] = useQuery({
    query,
    pause: !isEmpty(availableFeatureFlags),
  })
  useEffect(() => {
    if (data?.getFeatureFlags) {
      setPermission((prev) => [...new Set([...prev, ...data.getFeatureFlags])])
    }
  }, [data?.getFeatureFlags])

  /**
   * Check if the user has the required permissions
   * @param askFor - the permissions to check for
   * @returns boolean - return if the user has the required permissions, default to true if no permissions asked
   */
  const hasPermission = useCallback(
    (askFor?: string[], blackList?: string[]) => {
      if (isEmpty(askFor)) return true // if no permission are passed in, default to true
      if (!isEmpty(intersection(permissions, blackList))) return false
      return !isEmpty(intersection(permissions, askFor))
    },
    [permissions],
  )

  /**
   * Check if the user has the required role
   * @param role - the role to check for
   * @returns boolean - return if the user has the required role, default to true if no role is asked
   */
  const allowRole = useCallback(
    (askFor?: string[], blackList?: string[]) => {
      if (isEmpty(askFor)) return true
      if (!isEmpty(intersection(keys(roles), blackList))) return false
      return !isEmpty(
        flow([
          (ask) => pick(roles, ask),
          flatMap,
          (p) => intersection(permissions, p),
        ])(askFor),
      )
    },
    [roles, permissions],
  )
  return (
    <PermissionContext.Provider
      value={{ permissions, roles, hasPermission, allowRole }}
    >
      {children}
    </PermissionContext.Provider>
  )
}
