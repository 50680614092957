"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const AllProducts = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
  >
    <path d="M216-627.85v399.54q0 5.39 3.46 8.85t8.85 3.46h503.38q5.39 0 8.85-3.46t3.46-8.85v-399.54H604v263.77l-124-62-124 62v-263.77H216ZM228.31-164q-26.62 0-45.47-18.84Q164-201.69 164-228.31v-442.46q0-12.84 4.87-24.5 4.86-11.65 14.59-21.5l60.16-60.92q9.84-9.85 21.05-14.08t23.79-4.23h382.31q12.58 0 23.98 4.23T716-777.69L776.54-716q9.73 9.85 14.59 21.69 4.87 11.85 4.87 24.7v441.3q0 26.62-18.84 45.47Q758.31-164 731.69-164H228.31Zm-6.69-515.84H738l-57.62-59.93q-1.92-1.92-4.42-3.08-2.5-1.15-5.19-1.15H288.85q-2.69 0-5.2 1.15-2.5 1.16-4.42 3.08l-57.61 59.93ZM408-627.85v179.08l72-36 72 36v-179.08H408Zm-192 0h528-528Z" />
  </svg>,
  "AllProducts",
)

const AllProductsIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <AllProducts sx={{ fill: _fill, ...sx }} {...rest} />
}
export default AllProductsIcon
