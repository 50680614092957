"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Sea = createSvgIcon(
  <path d="M4.71925 17.9617L3.0827 11.9905L5.00003 11.3309V5.38477H10V2.38477H14V5.38477H19V11.3309L20.9943 12.029L19.2808 17.9617C18.6782 17.7694 18.1346 17.487 17.65 17.1146C17.1654 16.7422 16.6154 16.204 16 15.5002C15.4231 16.1912 14.8218 16.7806 14.1962 17.2684C13.5705 17.7563 12.8385 18.0002 12 18.0002C11.1616 18.0002 10.4295 17.7563 9.80387 17.2684C9.17823 16.7806 8.57694 16.1912 8.00003 15.5002C7.39746 16.204 6.85067 16.7422 6.35965 17.1146C5.86862 17.487 5.32182 17.7694 4.71925 17.9617ZM2.5 22.0002V21.0002H4.00003C4.70003 21.0002 5.39169 20.8918 6.07503 20.6752C6.75836 20.4585 7.40003 20.1335 8.00003 19.7002C8.60003 20.1335 9.24169 20.4502 9.92503 20.6502C10.6084 20.8502 11.3 20.9502 12 20.9502C12.7 20.9502 13.3917 20.8502 14.075 20.6502C14.7584 20.4502 15.4 20.1335 16 19.7002C16.6 20.1335 17.2417 20.4585 17.925 20.6752C18.6084 20.8918 19.3 21.0002 20 21.0002H21.5V22.0002H20C19.3 22.0002 18.6167 21.9168 17.95 21.7502C17.2834 21.5835 16.6334 21.3335 16 21.0002C15.3667 21.3335 14.7125 21.5835 14.0375 21.7502C13.3625 21.9168 12.6834 22.0002 12 22.0002C11.3167 22.0002 10.6375 21.9168 9.96253 21.7502C9.28753 21.5835 8.63336 21.3335 8.00003 21.0002C7.36669 21.3335 6.71669 21.5835 6.05003 21.7502C5.38336 21.9168 4.70003 22.0002 4.00003 22.0002H2.5ZM6.00003 10.9886L12 9.03862L18 10.9886V6.38479H6.00003V10.9886ZM12 17.0002C12.7834 17.0002 13.4395 16.7284 13.9683 16.1848C14.4972 15.6412 15.1744 14.8963 16 13.9502C16.7872 14.8502 17.3257 15.4604 17.6154 15.7809C17.9051 16.1015 18.25 16.3784 18.65 16.6117L19.75 12.6502L12 10.1002L4.23847 12.6502L5.35002 16.6002C5.75532 16.3668 6.10153 16.1002 6.38863 15.8002C6.67571 15.5002 7.21284 14.8835 8.00003 13.9502C8.83336 14.9002 9.51253 15.646 10.0375 16.1877C10.5625 16.7293 11.2167 17.0002 12 17.0002Z" />,
  "Sea",
)

const SeaIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary

  return <Sea sx={{ fill: _fill, ...sx }} {...rest} />
}

export default SeaIcon
