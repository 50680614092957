"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Delete = createSvgIcon(
  <path d="M6 19.9997V5.99972H5V4.99972H9V4.23047H15V4.99972H19V5.99972H18V19.9997H6ZM7 18.9997H17V5.99972H7V18.9997ZM9.80768 16.9997H10.8077V7.99972H9.80768V16.9997ZM13.1923 16.9997H14.1923V7.99972H13.1923V16.9997Z" />,
  "Delete",
)

const DeleteIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Delete sx={{ fill: _fill, ...sx }} {...rest} />
}

export default DeleteIcon
