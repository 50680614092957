"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const AllRequests = createSvgIcon(
  <path d="M5.8469 19.3845L4.17383 9.38449H19.8277L18.1546 19.3845H5.8469ZM6.67575 18.3845H17.3258L18.6008 10.3845H5.40075L6.67575 18.3845ZM10.0008 13.8845H14.0008C14.1424 13.8845 14.2612 13.8365 14.357 13.7405C14.4528 13.6446 14.5008 13.5257 14.5008 13.3838C14.5008 13.2419 14.4528 13.1233 14.357 13.0277C14.2612 12.9322 14.1424 12.8845 14.0008 12.8845H10.0008C9.85909 12.8845 9.74034 12.9325 9.6445 13.0284C9.54867 13.1244 9.50075 13.2433 9.50075 13.3852C9.50075 13.527 9.54867 13.6457 9.6445 13.7412C9.74034 13.8367 9.85909 13.8845 10.0008 13.8845ZM6.00075 7.99986C5.85909 7.99986 5.74034 7.95188 5.6445 7.85591C5.54867 7.75994 5.50075 7.64104 5.50075 7.49919C5.50075 7.35732 5.54867 7.23864 5.6445 7.14314C5.74034 7.04762 5.85909 6.99986 6.00075 6.99986H18.0008C18.1424 6.99986 18.2612 7.04784 18.357 7.14381C18.4528 7.23976 18.5008 7.35867 18.5008 7.50053C18.5008 7.64238 18.4528 7.76107 18.357 7.85658C18.2612 7.9521 18.1424 7.99986 18.0008 7.99986H6.00075ZM8.00075 5.61523C7.85909 5.61523 7.74034 5.56725 7.6445 5.47128C7.54867 5.37533 7.50075 5.25643 7.50075 5.11456C7.50075 4.97271 7.54867 4.85403 7.6445 4.75851C7.74034 4.66299 7.85909 4.61523 8.00075 4.61523H16.0008C16.1424 4.61523 16.2612 4.66322 16.357 4.75918C16.4528 4.85515 16.5008 4.97406 16.5008 5.11591C16.5008 5.25778 16.4528 5.37646 16.357 5.47196C16.2612 5.56748 16.1424 5.61523 16.0008 5.61523H8.00075Z" />,
  "AllRequests",
)

const AllRequestsIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <AllRequests sx={{ fill: _fill, ...sx }} {...rest} />
}
export default AllRequestsIcon
