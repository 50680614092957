/* eslint-disable */
import * as types from "./graphql"

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  mutation addOrUpdateWineProduct($product: B2GProductInput!) {\n    addProduct(product: $product, ingredients: []) {\n      id\n      commodity\n    }\n  }\n":
    types.AddOrUpdateWineProductDocument,
  '\n  mutation newReferencedDocument($filename: String = "") {\n    newReferencedDocument(filename: $filename) {\n      id\n      url\n    }\n  }\n':
    types.NewReferencedDocumentDocument,
  "\n  mutation newApiKey {\n    newSearchApiKey {\n      apiKey\n      expires\n    }\n  }\n":
    types.NewApiKeyDocument,
  "\n  mutation newPartyApiKey {\n    newPartyApiKey {\n      apiKey\n      expires\n    }\n  }\n":
    types.NewPartyApiKeyDocument,
  "\n  mutation deleteSupportingDocument($id: ID!) {\n    deleteReferencedDocument(id: $id)\n  }\n":
    types.DeleteSupportingDocumentDocument,
  '\n  mutation mutateDraftExportCertificateRequest(\n    $id: ID\n    $referencedDocuments: [B2GReferencedDocumentInput!]\n    $exporterDeclaration: B2GExporterDeclarationInput\n    $destination: B2GCountryInput\n    $consignment: B2GConsignmentInput\n    $commodity: String = ""\n    $certificateType: CertificateType\n    $certificateDelivery: B2GCertificateDeliveryInput\n    $requestName: String = ""\n  ) {\n    draftExportCertificateRequest(\n      request: {\n        id: $id\n        referencedDocuments: $referencedDocuments\n        exporterDeclaration: $exporterDeclaration\n        destination: $destination\n        consignment: $consignment\n        commodity: $commodity\n        certificateType: $certificateType\n        certificateDelivery: $certificateDelivery\n        requestName: $requestName\n      }\n    ) {\n      id\n      destination {\n        id\n      }\n      commodity\n      certificateType\n      market\n    }\n  }\n':
    types.MutateDraftExportCertificateRequestDocument,
  "\n  mutation mutateRaiseExportCertificateRequest($id: ID!) {\n    raiseExportCertificateRequest(id: $id) {\n      id\n    }\n  }\n":
    types.MutateRaiseExportCertificateRequestDocument,
  "\n  mutation mutateDeleteExportRequest($id: ID!) {\n    deleteExportRequest(id: $id)\n  }\n":
    types.MutateDeleteExportRequestDocument,
  "\n  mutation CreateSensoryProcess(\n    $productId: ID!\n    $reference: String!\n    $disputed: Boolean!\n    $disputedReason: WineDisputedReason\n    $comment: String\n    $winemaker: String!\n    $assessmentDate: AWSDateTime!\n  ) {\n    completeSensoryProcess(\n      input: {\n        productId: $productId\n        reference: $reference\n        disputed: $disputed\n        disputedReason: $disputedReason\n        comment: $comment\n        winemaker: { personName: $winemaker }\n        assessmentDate: $assessmentDate\n      }\n    ) {\n      id\n      productId\n      reference\n      disputed\n      disputedReason\n      comment\n      winemaker {\n        personName\n      }\n      assessmentDate\n    }\n  }\n":
    types.CreateSensoryProcessDocument,
  "\n  mutation RequestChemistryTest(\n    $productId: ID!\n    $expectedAlcoholContent: Float\n    $laboratory: String!\n    $packagingReference: String\n    $packagingDate: AWSDateTime\n    $sampleCollectionDate: AWSDateTime!\n    $testRequired: ID!\n  ) {\n    requestChemistryTest(\n      input: {\n        productId: $productId\n        attributes: {\n          expectedAlcoholContent: $expectedAlcoholContent\n          laboratory: $laboratory\n          packagingReference: $packagingReference\n          packagingDate: $packagingDate\n          sampleCollectionDate: $sampleCollectionDate\n          testRequired: $testRequired\n        }\n      }\n    ) {\n      status\n      cancelledReason\n      firstResultReportedDate\n      receipt {\n        laboratoryReference\n        receiptDate\n        sampleSuitable\n        unsuitableReason\n      }\n      request {\n        expectedAlcoholContent\n        laboratory\n        packagingDate\n        packagingReference\n        sampleCollectionDate\n        testRequired\n        sampleReference\n        wineTestReference\n      }\n    }\n  }\n":
    types.RequestChemistryTestDocument,
  "\n  mutation AddBottlingRuns($input: BottlingRunInput!) {\n    addBottlingRuns(input: $input) {\n      processId\n      runs {\n        id\n        bottleVolume\n        bottlesPerCase\n        cases\n        date\n        extraBottles\n        reference\n      }\n    }\n  }\n":
    types.AddBottlingRunsDocument,
  "\n  mutation AddFlexiFillingRuns($input: FlexiFillingRunInput!) {\n    addFlexiFillingRuns(input: $input) {\n      processId\n      runs {\n        date\n        litres\n        id\n        reference\n      }\n    }\n  }\n":
    types.AddFlexiFillingRunsDocument,
  "\n  mutation StartPackaging($productId: ID!) {\n    startPackaging(productId: $productId) {\n      id\n      type\n      task {\n        taskId\n        assignedTo\n      }\n    }\n  }\n":
    types.StartPackagingDocument,
  "\n  mutation RequestConfirmation($input: RequestConfirmationInput!) {\n    requestConfirmation(input: $input) {\n      wsmp\n      processId\n    }\n  }\n":
    types.RequestConfirmationDocument,
  "\n  mutation ConfirmPackaging($input: ConfirmPackagingInput!) {\n    confirmPackaging(input: $input) {\n      confirmed\n      disputeReason\n      disputeResolution\n      processId\n    }\n  }\n":
    types.ConfirmPackagingDocument,
  "\n  mutation CancelProcess($processId: ID!, $cancelledReason: String) {\n    cancelProcess(\n      input: { processId: $processId, cancelledReason: $cancelledReason }\n    ) {\n      status\n      cancelledReason\n      firstResultReportedDate\n      receipt {\n        laboratoryReference\n        receiptDate\n        sampleSuitable\n        unsuitableReason\n      }\n      request {\n        expectedAlcoholContent\n        laboratory\n        packagingDate\n        packagingReference\n        sampleCollectionDate\n        testRequired\n        sampleReference\n        wineTestReference\n      }\n    }\n  }\n":
    types.CancelProcessDocument,
  "\n  mutation StartWinemaking($productId: ID!) {\n    startWinemaking(productId: $productId) {\n      type\n      task {\n        assignedTo\n        taskId\n      }\n      id\n    }\n  }\n":
    types.StartWinemakingDocument,
  "\n  mutation ConfirmWinemaking($input: ConfirmWinemakingInput!) {\n    confirmWinemaking(input: $input)\n  }\n":
    types.ConfirmWinemakingDocument,
  "\n  mutation ResetConfirmationRequest($input: ResetConfirmationRequestInput!) {\n    resetConfirmationRequest(input: $input)\n  }\n":
    types.ResetConfirmationRequestDocument,
  "\n  query listFeatureFlags {\n    getFeatureFlags\n  }\n":
    types.ListFeatureFlagsDocument,
  "\n  query productForm(\n    $certificateType: CertificateType\n    $country: String\n    $commodity: String\n  ) {\n    productForm(\n      input: {\n        certificateType: $certificateType\n        commodity: $commodity\n        country: $country\n      }\n    ) {\n      type\n      required\n      readonly\n      numberOfLines\n      nested {\n        type\n        id\n        collection\n      }\n      label\n      id\n      options {\n        value\n        label\n      }\n      description\n      collection\n      numberConstrain {\n        step\n        min\n        max\n      }\n      filter {\n        attribute\n        value\n      }\n    }\n  }\n":
    types.ProductFormDocument,
  "\n  query queryExportCertificateBase($id: ID!) {\n    exportCertificateRequest(id: $id) {\n      id\n      commodity\n      certificateType\n      destination {\n        id\n      }\n    }\n  }\n":
    types.QueryExportCertificateBaseDocument,
  "\n  query queryExportCertificateDetails($id: ID!) {\n    exportCertificateRequest(id: $id) {\n      id\n      status\n      certificateNumber\n    }\n  }\n":
    types.QueryExportCertificateDetailsDocument,
  "\n  query referencedDocument($id: ID!) {\n    referencedDocument(id: $id) {\n      uploadInProgress\n      id\n      filename\n    }\n  }\n":
    types.ReferencedDocumentDocument,
  "\n  query downloadSupportingDocument($id: ID!) {\n    downloadReferencedDocument(id: $id) {\n      documentName\n      url\n    }\n  }\n":
    types.DownloadSupportingDocumentDocument,
  "\n  query GetProduct($id: ID!) {\n    product(id: $id) {\n      id\n      productNumber\n      modifiable\n      description\n      wine {\n        totalPackagedLitres\n        availableLitres\n        exportedLitres\n        shippingFormat\n        EUCategory\n      }\n      eligibilityStatus {\n        market\n        status\n        expiryDate\n      }\n    }\n  }\n":
    types.GetProductDocument,
  "\n  query queryValidate($id: ID!) {\n    validate(id: $id) {\n      error\n      path\n      type\n    }\n  }\n":
    types.QueryValidateDocument,
  "\n  query queryValidateProduct($id: ID!) {\n    validateProduct(id: $id) {\n      error\n      path\n      type\n    }\n  }\n":
    types.QueryValidateProductDocument,
  "\n  query querySfExporter($id: ID!) {\n    sfExporter(id: $id) {\n      addresses {\n        addressType\n        city\n        country\n        street\n        postalCode\n        isPrimary\n      }\n      nzbn\n      name\n      id\n      contacts {\n        emails {\n          emailAddress\n          isPrimary\n        }\n        name\n        phones {\n          telephoneNumber\n          isPrimary\n        }\n        role\n      }\n      identifiers {\n        value\n        id\n        description\n      }\n    }\n  }\n":
    types.QuerySfExporterDocument,
  "\n  query getAvailableProcesses($productId: ID!) {\n    availableProcesses(productId: $productId) {\n      description\n      id\n    }\n  }\n":
    types.GetAvailableProcessesDocument,
  "\n  query queryProductProcessesSensoryEvaluation($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: SENSORY } }\n    ) {\n      processId\n      processType\n      taskId\n      attributes {\n        ... on SensoryProcessRequest {\n          assessmentDate\n          reference\n          disputed\n          disputedReason\n          id\n          productId\n          winemaker {\n            personName\n          }\n          comment\n        }\n      }\n    }\n  }\n":
    types.QueryProductProcessesSensoryEvaluationDocument,
  "\n  query queryProductProcessesChemistryTest($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: CHEMISTRY } }\n    ) {\n      processId\n      processType\n      taskId\n      attributes {\n        ... on ChemistryTestResponse {\n          status\n          cancelledReason\n          firstResultReportedDate\n          receipt {\n            laboratoryReference\n            receiptDate\n            sampleSuitable\n            unsuitableReason\n          }\n          request {\n            expectedAlcoholContent\n            laboratory\n            packagingDate\n            packagingReference\n            sampleCollectionDate\n            sampleReference\n            testRequired\n            wineTestReference\n          }\n        }\n      }\n    }\n  }\n":
    types.QueryProductProcessesChemistryTestDocument,
  "\n  query QueryProductProcessBottlingRuns($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: BOTTLING_RUN } }\n    ) {\n      attributes {\n        ... on Packaging {\n          wsmp\n          exportedLitres\n          confirmation {\n            confirmed\n            disputeReason\n            disputeResolution\n          }\n          availableLitres\n          runs {\n            ... on BottlingRunData {\n              id\n              bottleVolume\n              bottlesPerCase\n              cases\n              date\n              extraBottles\n              litres\n              reference\n            }\n          }\n          status\n          totalPackagedLitres\n          volumeAdjustment {\n            date\n            litres\n            name\n            reason\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n":
    types.QueryProductProcessBottlingRunsDocument,
  "\n  query QueryProductProcessFillingRuns($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: FLEXI_FILLING } }\n    ) {\n      attributes {\n        ... on Packaging {\n          wsmp\n          exportedLitres\n          confirmation {\n            confirmed\n            disputeReason\n            disputeResolution\n          }\n          availableLitres\n          runs {\n            ... on FlexiFillingRunData {\n              date\n              id\n              litres\n              reference\n            }\n          }\n          status\n          totalPackagedLitres\n          volumeAdjustment {\n            date\n            litres\n            name\n            reason\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n":
    types.QueryProductProcessFillingRunsDocument,
  "\n  query queryProductDeclaration($commodity: String!) {\n    productFormlet(input: { commodity: $commodity }) {\n      declarations {\n        id\n        type\n        fields {\n          market {\n            id\n            value\n            label\n          }\n          text {\n            id\n            type\n            label\n          }\n          eligibilityCriteria {\n            id\n            value\n          }\n        }\n      }\n    }\n  }\n":
    types.QueryProductDeclarationDocument,
  "\n  query FindAvailableTemplates($commodity: String!, $countryCode: String!) {\n    availableTemplates(commodity: $commodity, countryCode: $countryCode) {\n      id\n      code\n      title\n      exportCertificateType\n      commodity\n      destinationMarket\n      displayedDescription\n      minorVersion\n      majorVersion\n    }\n  }\n":
    types.FindAvailableTemplatesDocument,
  "\n  query queryProductProcessWinemaking($id: ID!) {\n    productProcesses(\n      input: { filter: { processType: WINE_MAKING }, productId: $id }\n    ) {\n      attributes {\n        ... on Winemaking {\n          wsmp\n          confirmations {\n            status\n            market\n            disputedResolution\n            disputedReason\n            confirmed\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n":
    types.QueryProductProcessWinemakingDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation addOrUpdateWineProduct($product: B2GProductInput!) {\n    addProduct(product: $product, ingredients: []) {\n      id\n      commodity\n    }\n  }\n",
): typeof import("./graphql").AddOrUpdateWineProductDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation newReferencedDocument($filename: String = "") {\n    newReferencedDocument(filename: $filename) {\n      id\n      url\n    }\n  }\n',
): typeof import("./graphql").NewReferencedDocumentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation newApiKey {\n    newSearchApiKey {\n      apiKey\n      expires\n    }\n  }\n",
): typeof import("./graphql").NewApiKeyDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation newPartyApiKey {\n    newPartyApiKey {\n      apiKey\n      expires\n    }\n  }\n",
): typeof import("./graphql").NewPartyApiKeyDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteSupportingDocument($id: ID!) {\n    deleteReferencedDocument(id: $id)\n  }\n",
): typeof import("./graphql").DeleteSupportingDocumentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation mutateDraftExportCertificateRequest(\n    $id: ID\n    $referencedDocuments: [B2GReferencedDocumentInput!]\n    $exporterDeclaration: B2GExporterDeclarationInput\n    $destination: B2GCountryInput\n    $consignment: B2GConsignmentInput\n    $commodity: String = ""\n    $certificateType: CertificateType\n    $certificateDelivery: B2GCertificateDeliveryInput\n    $requestName: String = ""\n  ) {\n    draftExportCertificateRequest(\n      request: {\n        id: $id\n        referencedDocuments: $referencedDocuments\n        exporterDeclaration: $exporterDeclaration\n        destination: $destination\n        consignment: $consignment\n        commodity: $commodity\n        certificateType: $certificateType\n        certificateDelivery: $certificateDelivery\n        requestName: $requestName\n      }\n    ) {\n      id\n      destination {\n        id\n      }\n      commodity\n      certificateType\n      market\n    }\n  }\n',
): typeof import("./graphql").MutateDraftExportCertificateRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation mutateRaiseExportCertificateRequest($id: ID!) {\n    raiseExportCertificateRequest(id: $id) {\n      id\n    }\n  }\n",
): typeof import("./graphql").MutateRaiseExportCertificateRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation mutateDeleteExportRequest($id: ID!) {\n    deleteExportRequest(id: $id)\n  }\n",
): typeof import("./graphql").MutateDeleteExportRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateSensoryProcess(\n    $productId: ID!\n    $reference: String!\n    $disputed: Boolean!\n    $disputedReason: WineDisputedReason\n    $comment: String\n    $winemaker: String!\n    $assessmentDate: AWSDateTime!\n  ) {\n    completeSensoryProcess(\n      input: {\n        productId: $productId\n        reference: $reference\n        disputed: $disputed\n        disputedReason: $disputedReason\n        comment: $comment\n        winemaker: { personName: $winemaker }\n        assessmentDate: $assessmentDate\n      }\n    ) {\n      id\n      productId\n      reference\n      disputed\n      disputedReason\n      comment\n      winemaker {\n        personName\n      }\n      assessmentDate\n    }\n  }\n",
): typeof import("./graphql").CreateSensoryProcessDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation RequestChemistryTest(\n    $productId: ID!\n    $expectedAlcoholContent: Float\n    $laboratory: String!\n    $packagingReference: String\n    $packagingDate: AWSDateTime\n    $sampleCollectionDate: AWSDateTime!\n    $testRequired: ID!\n  ) {\n    requestChemistryTest(\n      input: {\n        productId: $productId\n        attributes: {\n          expectedAlcoholContent: $expectedAlcoholContent\n          laboratory: $laboratory\n          packagingReference: $packagingReference\n          packagingDate: $packagingDate\n          sampleCollectionDate: $sampleCollectionDate\n          testRequired: $testRequired\n        }\n      }\n    ) {\n      status\n      cancelledReason\n      firstResultReportedDate\n      receipt {\n        laboratoryReference\n        receiptDate\n        sampleSuitable\n        unsuitableReason\n      }\n      request {\n        expectedAlcoholContent\n        laboratory\n        packagingDate\n        packagingReference\n        sampleCollectionDate\n        testRequired\n        sampleReference\n        wineTestReference\n      }\n    }\n  }\n",
): typeof import("./graphql").RequestChemistryTestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation AddBottlingRuns($input: BottlingRunInput!) {\n    addBottlingRuns(input: $input) {\n      processId\n      runs {\n        id\n        bottleVolume\n        bottlesPerCase\n        cases\n        date\n        extraBottles\n        reference\n      }\n    }\n  }\n",
): typeof import("./graphql").AddBottlingRunsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation AddFlexiFillingRuns($input: FlexiFillingRunInput!) {\n    addFlexiFillingRuns(input: $input) {\n      processId\n      runs {\n        date\n        litres\n        id\n        reference\n      }\n    }\n  }\n",
): typeof import("./graphql").AddFlexiFillingRunsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation StartPackaging($productId: ID!) {\n    startPackaging(productId: $productId) {\n      id\n      type\n      task {\n        taskId\n        assignedTo\n      }\n    }\n  }\n",
): typeof import("./graphql").StartPackagingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation RequestConfirmation($input: RequestConfirmationInput!) {\n    requestConfirmation(input: $input) {\n      wsmp\n      processId\n    }\n  }\n",
): typeof import("./graphql").RequestConfirmationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ConfirmPackaging($input: ConfirmPackagingInput!) {\n    confirmPackaging(input: $input) {\n      confirmed\n      disputeReason\n      disputeResolution\n      processId\n    }\n  }\n",
): typeof import("./graphql").ConfirmPackagingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CancelProcess($processId: ID!, $cancelledReason: String) {\n    cancelProcess(\n      input: { processId: $processId, cancelledReason: $cancelledReason }\n    ) {\n      status\n      cancelledReason\n      firstResultReportedDate\n      receipt {\n        laboratoryReference\n        receiptDate\n        sampleSuitable\n        unsuitableReason\n      }\n      request {\n        expectedAlcoholContent\n        laboratory\n        packagingDate\n        packagingReference\n        sampleCollectionDate\n        testRequired\n        sampleReference\n        wineTestReference\n      }\n    }\n  }\n",
): typeof import("./graphql").CancelProcessDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation StartWinemaking($productId: ID!) {\n    startWinemaking(productId: $productId) {\n      type\n      task {\n        assignedTo\n        taskId\n      }\n      id\n    }\n  }\n",
): typeof import("./graphql").StartWinemakingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ConfirmWinemaking($input: ConfirmWinemakingInput!) {\n    confirmWinemaking(input: $input)\n  }\n",
): typeof import("./graphql").ConfirmWinemakingDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ResetConfirmationRequest($input: ResetConfirmationRequestInput!) {\n    resetConfirmationRequest(input: $input)\n  }\n",
): typeof import("./graphql").ResetConfirmationRequestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query listFeatureFlags {\n    getFeatureFlags\n  }\n",
): typeof import("./graphql").ListFeatureFlagsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query productForm(\n    $certificateType: CertificateType\n    $country: String\n    $commodity: String\n  ) {\n    productForm(\n      input: {\n        certificateType: $certificateType\n        commodity: $commodity\n        country: $country\n      }\n    ) {\n      type\n      required\n      readonly\n      numberOfLines\n      nested {\n        type\n        id\n        collection\n      }\n      label\n      id\n      options {\n        value\n        label\n      }\n      description\n      collection\n      numberConstrain {\n        step\n        min\n        max\n      }\n      filter {\n        attribute\n        value\n      }\n    }\n  }\n",
): typeof import("./graphql").ProductFormDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryExportCertificateBase($id: ID!) {\n    exportCertificateRequest(id: $id) {\n      id\n      commodity\n      certificateType\n      destination {\n        id\n      }\n    }\n  }\n",
): typeof import("./graphql").QueryExportCertificateBaseDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryExportCertificateDetails($id: ID!) {\n    exportCertificateRequest(id: $id) {\n      id\n      status\n      certificateNumber\n    }\n  }\n",
): typeof import("./graphql").QueryExportCertificateDetailsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query referencedDocument($id: ID!) {\n    referencedDocument(id: $id) {\n      uploadInProgress\n      id\n      filename\n    }\n  }\n",
): typeof import("./graphql").ReferencedDocumentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query downloadSupportingDocument($id: ID!) {\n    downloadReferencedDocument(id: $id) {\n      documentName\n      url\n    }\n  }\n",
): typeof import("./graphql").DownloadSupportingDocumentDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetProduct($id: ID!) {\n    product(id: $id) {\n      id\n      productNumber\n      modifiable\n      description\n      wine {\n        totalPackagedLitres\n        availableLitres\n        exportedLitres\n        shippingFormat\n        EUCategory\n      }\n      eligibilityStatus {\n        market\n        status\n        expiryDate\n      }\n    }\n  }\n",
): typeof import("./graphql").GetProductDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryValidate($id: ID!) {\n    validate(id: $id) {\n      error\n      path\n      type\n    }\n  }\n",
): typeof import("./graphql").QueryValidateDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryValidateProduct($id: ID!) {\n    validateProduct(id: $id) {\n      error\n      path\n      type\n    }\n  }\n",
): typeof import("./graphql").QueryValidateProductDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query querySfExporter($id: ID!) {\n    sfExporter(id: $id) {\n      addresses {\n        addressType\n        city\n        country\n        street\n        postalCode\n        isPrimary\n      }\n      nzbn\n      name\n      id\n      contacts {\n        emails {\n          emailAddress\n          isPrimary\n        }\n        name\n        phones {\n          telephoneNumber\n          isPrimary\n        }\n        role\n      }\n      identifiers {\n        value\n        id\n        description\n      }\n    }\n  }\n",
): typeof import("./graphql").QuerySfExporterDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAvailableProcesses($productId: ID!) {\n    availableProcesses(productId: $productId) {\n      description\n      id\n    }\n  }\n",
): typeof import("./graphql").GetAvailableProcessesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryProductProcessesSensoryEvaluation($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: SENSORY } }\n    ) {\n      processId\n      processType\n      taskId\n      attributes {\n        ... on SensoryProcessRequest {\n          assessmentDate\n          reference\n          disputed\n          disputedReason\n          id\n          productId\n          winemaker {\n            personName\n          }\n          comment\n        }\n      }\n    }\n  }\n",
): typeof import("./graphql").QueryProductProcessesSensoryEvaluationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryProductProcessesChemistryTest($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: CHEMISTRY } }\n    ) {\n      processId\n      processType\n      taskId\n      attributes {\n        ... on ChemistryTestResponse {\n          status\n          cancelledReason\n          firstResultReportedDate\n          receipt {\n            laboratoryReference\n            receiptDate\n            sampleSuitable\n            unsuitableReason\n          }\n          request {\n            expectedAlcoholContent\n            laboratory\n            packagingDate\n            packagingReference\n            sampleCollectionDate\n            sampleReference\n            testRequired\n            wineTestReference\n          }\n        }\n      }\n    }\n  }\n",
): typeof import("./graphql").QueryProductProcessesChemistryTestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query QueryProductProcessBottlingRuns($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: BOTTLING_RUN } }\n    ) {\n      attributes {\n        ... on Packaging {\n          wsmp\n          exportedLitres\n          confirmation {\n            confirmed\n            disputeReason\n            disputeResolution\n          }\n          availableLitres\n          runs {\n            ... on BottlingRunData {\n              id\n              bottleVolume\n              bottlesPerCase\n              cases\n              date\n              extraBottles\n              litres\n              reference\n            }\n          }\n          status\n          totalPackagedLitres\n          volumeAdjustment {\n            date\n            litres\n            name\n            reason\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n",
): typeof import("./graphql").QueryProductProcessBottlingRunsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query QueryProductProcessFillingRuns($productId: ID!) {\n    productProcesses(\n      input: { productId: $productId, filter: { processType: FLEXI_FILLING } }\n    ) {\n      attributes {\n        ... on Packaging {\n          wsmp\n          exportedLitres\n          confirmation {\n            confirmed\n            disputeReason\n            disputeResolution\n          }\n          availableLitres\n          runs {\n            ... on FlexiFillingRunData {\n              date\n              id\n              litres\n              reference\n            }\n          }\n          status\n          totalPackagedLitres\n          volumeAdjustment {\n            date\n            litres\n            name\n            reason\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n",
): typeof import("./graphql").QueryProductProcessFillingRunsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryProductDeclaration($commodity: String!) {\n    productFormlet(input: { commodity: $commodity }) {\n      declarations {\n        id\n        type\n        fields {\n          market {\n            id\n            value\n            label\n          }\n          text {\n            id\n            type\n            label\n          }\n          eligibilityCriteria {\n            id\n            value\n          }\n        }\n      }\n    }\n  }\n",
): typeof import("./graphql").QueryProductDeclarationDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query FindAvailableTemplates($commodity: String!, $countryCode: String!) {\n    availableTemplates(commodity: $commodity, countryCode: $countryCode) {\n      id\n      code\n      title\n      exportCertificateType\n      commodity\n      destinationMarket\n      displayedDescription\n      minorVersion\n      majorVersion\n    }\n  }\n",
): typeof import("./graphql").FindAvailableTemplatesDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query queryProductProcessWinemaking($id: ID!) {\n    productProcesses(\n      input: { filter: { processType: WINE_MAKING }, productId: $id }\n    ) {\n      attributes {\n        ... on Winemaking {\n          wsmp\n          confirmations {\n            status\n            market\n            disputedResolution\n            disputedReason\n            confirmed\n          }\n        }\n      }\n      confirmationTaskId\n      requestTaskId\n      resetTaskId\n      taskId\n      processType\n      processId\n    }\n  }\n",
): typeof import("./graphql").QueryProductProcessWinemakingDocument

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}
