"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Copy = createSvgIcon(
  <path d="M7 18V2H15.5L20 6.5V18H7ZM15 7V3H8V17H19V7H15ZM3 22V8H4V21H14V22H3Z" />,
  "Copy",
)

const CopyIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Copy sx={{ fill: _fill, ...sx }} {...rest} />
}

export default CopyIcon
