"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Air = createSvgIcon(
  // <svg
  //   width="40"
  //   height="40"
  //   viewBox="0 0 40 40"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <g>
  //     <path d="M12.4117 27.5895L8.03994 25.2177L9.02069 24.2369L12.8989 24.8202L19.8156 17.9036L7.55273 11.2689L8.79636 9.9933L23.8797 13.8202L28.7867 8.90034C29.1094 8.5777 29.4913 8.41638 29.9326 8.41638C30.3738 8.41638 30.7558 8.5777 31.0784 8.90034C31.4011 9.22298 31.5624 9.61027 31.5624 10.0622C31.5624 10.5141 31.4011 10.9014 31.0784 11.224L26.213 16.1536L30.0079 31.2048L28.7643 32.4805L22.0977 20.2177L15.2451 27.0702L15.7964 30.9805L14.7835 31.9933L12.4117 27.5895Z" />
  //   </g>
  // </svg>,
  <path d="M5.41173 19.5895L1.03994 17.2177L2.02069 16.2369L5.8989 16.8202L12.8156 9.90355L0.552734 3.26892L1.79636 1.9933L16.8797 5.82022L21.7867 0.90034C22.1094 0.577701 22.4913 0.416382 22.9326 0.416382C23.3738 0.416382 23.7558 0.577701 24.0784 0.90034C24.4011 1.22298 24.5624 1.61027 24.5624 2.06222C24.5624 2.51413 24.4011 2.90141 24.0784 3.22405L19.213 8.15355L23.0079 23.2048L21.7643 24.4805L15.0977 12.2177L8.24507 19.0702L8.79636 22.9805L7.78353 23.9933L5.41173 19.5895Z" />,
  "Air",
)

const AirIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Air sx={{ fill: _fill, ...sx }} {...rest} />
}
export default AirIcon
