"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Wine = createSvgIcon(
  <path d="M8.69247 20.0005V19.0005H11.5002V14.4774C10.0668 14.2569 8.93927 13.6319 8.11747 12.6024C7.29567 11.5729 6.88477 10.3723 6.88477 9.00049V4.00049H17.1156V9.00049C17.1156 10.3723 16.7047 11.5729 15.8829 12.6024C15.0611 13.6319 13.9335 14.2569 12.5002 14.4774V19.0005H15.3079V20.0005H8.69247ZM12.0002 13.5005C13.0489 13.5005 13.9617 13.1146 14.7386 12.3428C15.5155 11.571 15.963 10.6236 16.0809 9.50049H7.91939C8.03734 10.6236 8.48478 11.571 9.26172 12.3428C10.0386 13.1146 10.9514 13.5005 12.0002 13.5005ZM7.88479 8.50049H16.1155V5.00049H7.88479V8.50049Z" />,
  "Wine",
)

const WineIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary

  return <Wine sx={{ fill: _fill, ...sx }} {...rest} />
}

export default WineIcon
